.App {
  text-align: center;
}

.chart {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 50em;
}

.comparisonList {
  background-color: #eee;
}

.comparisonForm {
  background-color: #eee;
}

.drivetrains {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.drivetrain {
  background-color: #ddd;
  margin: .2em;
  padding: .5em
}

.drivetrainTitle a {
  text-decoration: none;
  color: gray;
  font-size: small;
}

.gearRatios {
  display: flex;
}

.drivetrainInput {
  width: 7ch;
}

.comparisonList a {
    text-decoration: none;
}

.comparisonName {
  color: #0000dd;
}

.comparisonName a {
  color: #000099;
}

.comparisonButtons {
  color: gray;
  font-size: small;
}
